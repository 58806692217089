:root {
  --color-white: #ffffff;
  --color-white-shade1: #fafafa;
  --color-white-shade2: #d9d9d9;
  --color-black: #000000;
  --color-black-shade1: #000000e0;
  --color-primary: #ef3f3e;
  --color-secondary: #fa8c15;
  --color-red: #e92424;
  --color-primary-light: #e86363;
  --color-border: #0000001f;
  --color-border-hover: #0000004d;
  --color-blue-shade1: #121d45;
  --color-grey-light: #ccc;
  --color-sky-light: #dde1ee;
  --color-shadow1: #0000001a;
  --color-shadow2: #0000000d;
  --color-text: #545454;
  --Neutral-10: #141414;
  --text-dark: #161616;
  --background-2: #f2f4f7;
  --primary-tint-2: #66a9d9;
  --primary-tint-2-shadow: #006fc040;
  --primary-tint-1: #cce2f2;
  --blue: #006fc0;
  --blue-hover: #0092fc;
  --text-medium: #777;
  --color-red: #e64e2c;
  --color-red-light: #fff1f0;
}

/*breakpoint mixin start here*/
@screen1450: ~'only screen and (max-width: 1450px)';
@screen1150: ~'only screen and (max-width: 1150px)';
@screen1024: ~'only screen and (max-width: 1024px)';
@screen991: ~'only screen and (max-width: 991px)';
@screen767: ~'only screen and (max-width: 767px)';
@screen540: ~'only screen and (max-width: 540px)';
@screen375: ~'only screen and (max-width: 375px)';
@screen320: ~'only screen and (max-width: 320px)';
/*breakpoint mixin end here*/

// ColorPicker Variables
@white-color: var(--color-white);
@black-color: var(--color-black);

//global style variables
@secondary-color: var(--color-secondary);
@text-color: var(--color-text);

@border-radius-base: 4px;
@border-radius-sm: 4px;
@border-radius-lg: 10px;
@layout-header-padding: 0 32px;
@card-actions-li-margin: 12px 24px;

// @font-size-base: 14px;
// @font-size-lg: @font-size-base + 0.1em;
// @font-size-sm: 12px;

// font-size: clamp(min, value, max);

@heading-1-size: clamp(25px, 4.6vw, 38px);
@heading-2-size: clamp(20px, 4vw, 30px);
@heading-3-size: clamp(18px, 3.8vw, 24px);
@heading-4-size: clamp(16px, 3vw, 20px);
@heading-5-size: clamp(14px, 2.5vw, 16px);

/*typography css end here*/
//*all variable list link are here*//
/**https://github.com/ant-design/ant-design/blob/master/components/style/themes/variable.less**/
